import { useContext, useEffect, useState } from "react";
import styles from './feedback.module.scss';
import { InfoMessage, InfoMessageType } from "../talents/components/misc/InfoMessage";
import { MONTHS, yearsHardCode, yearsOrder } from "../../core/talent-module/hardcode";
import { Pagination } from "../../components/talent-module/pagination/Pagination";
import { AppContext } from "../candidates/context/AppProvider";
import { serializeSelectValue, unsearializaDateString } from "../../core/helper";
import arrowView from '../../../assets/icons/table_detail_arrow.svg';
import { defaultStringEmpty } from "../../core/talent-module/helper";
import { FeedbackDetail } from "./components/FeedbackDetail";
import { FeedbackArrows } from "./components/FeedbackArrows";
import { FeedbackStarts } from "./components/FeedbackStarts";
import { getFeedbackStatus } from "../../api/feedbackClient";
import { TalentList } from "../talents/components/list/TalentList";

export const getStatusDescription = (id, catFeedbackStatus) => {
    let statusDescription = '';
    const feedbackStatus = catFeedbackStatus.find( c => c.id === id);
    if( feedbackStatus ) {
        statusDescription = feedbackStatus.name;
    }
    return statusDescription;
}

export const setSelectDates = review => {
    const reviewTmp = { ...review };
    const yearHardCode = yearsHardCode(yearsOrder.desc);
    const reviewDate = unsearializaDateString(reviewTmp.date);
    const dayLength = reviewDate.day.length;
    const reviewDay = dayLength === 2 ? reviewDate.day : ( dayLength > 2 ? reviewDate.day.substring(0, 2) : reviewDate.day );
    const reviewMes = MONTHS.find( m => m.id === reviewDate.monthId );
    const reviewAnio = yearHardCode.find( y => y.optionName === reviewDate.yearDesc);
    reviewTmp.reviewDay = reviewDay;
    reviewTmp.reviewMes = serializeSelectValue(reviewMes);
    reviewTmp.reviewAnio = serializeSelectValue(reviewAnio);
    return reviewTmp;
}

export const FeedbackList = ({
    evaluations,
    setEvaluations,
    catType,
    tab,
    setTab
}) => {
    const appContext = useContext(AppContext);
    const pageSize = 5;
    const [reviews, setReviews] = useState([]);
    const [reviewSelected, setReviewSelected] = useState();
    const [reviewsPagged, setReviewsPagged] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [catFeedbackStatus, setCatFeedbackStatus] = useState([]);
    const [isFirstReport, setIsFirstReport] = useState(false);

    const loadFeedbackStatus = () => {
        getFeedbackStatus(appContext.userData.token).then(({ status, data }) => {
            if( status === 200 && data.code === 202 ) {
                setCatFeedbackStatus(data.result);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const sorterDate = (date1, date2) => {
        let result = 0;
        if (date1 < date2) {
            result = 1
        } else if (date1 > date2) {
            result = -1
        }
        return result;
    }

    const loadEvaluations = () => {
        let reviewsTmp = [];
        evaluations.map( review => {
            const reWithDates = setSelectDates(review);
            reWithDates.period = `${reWithDates.reviewDay} ${appContext.t('paginator.of')} ${reWithDates.reviewMes.description} ${reWithDates.reviewAnio.description}`;
            reviewsTmp.push(reWithDates);
        });
        reviewsTmp = reviewsTmp.sort((a, b) => sorterDate(a.date, b.date));
        setReviews(reviewsTmp);
        setTotalPages(Math.ceil(reviewsTmp.length/pageSize));
        setReviewsPagged(reviewsTmp.slice(0, pageSize));
    }

    const setNavigationDetail = (review, menuNavContext, onEvent, arg) => {
        let menuNavigation = [ ...menuNavContext ];
        menuNavigation.pop();
        menuNavigation.push({
            label: defaultStringEmpty(`${appContext.talent.firstName} ${appContext.talent.lastName}`),
            'onEvent': onEvent,
            'arg': arg
        });
        menuNavigation.push({ label: `${appContext.t('candidate.resume.review.title')} ${review.period.substring(0, review.period.lastIndexOf(' '))}` });
        appContext.setMenuNavigation(menuNavigation);
    }

    useEffect(() => {
        loadFeedbackStatus();
        loadEvaluations();
        if( evaluations && evaluations.length > 0 ) {
            const stageIndexs = evaluations.map(({stageIndex}) => stageIndex).filter((value, index, self) => self.indexOf(value) === index);
            setIsFirstReport(stageIndexs.length === 1);
        }
    }, [evaluations]);

    useEffect(() => {
        if( tab === 1 && !appContext.feedback) {
            setReviewSelected(null);
        } else if ( tab === 1 && appContext.feedback ){
            setTimeout(() => {
                setReviewSelected(appContext.feedback);
            }, 2000);
        }
        if (appContext.feedback && Object.keys(appContext.talent).length > 1) {
            const menuNavigation = [
                { label: appContext.t('menu.talent.opt1'), component: <TalentList /> },
                { label: defaultStringEmpty(`${appContext.talent.firstName} ${appContext.talent.lastName}`)}
            ]
            setNavigationDetail(appContext.feedback, menuNavigation, () => {
                setTab(1);
            });
        }
    }, [appContext.feedback, appContext.talent, tab])
    
    const onClickDetail = review => {
        // const stageIndexs = evaluations.map(({stageIndex}) => stageIndex).filter((value, index, self) => self.indexOf(value) === index);
        // setIsFirstReport(stageIndexs.length === 1);
        setNavigationDetail(review, appContext.menuNavigation, setTab, 1);
        appContext.contentScrollUp();
        setReviewSelected(review);
        setTab(2);
    }

    const onClickBackPage = () => {
        if( currentPage > 0 ) {
            const prevPage = currentPage - 1;
            const start = prevPage * pageSize;
            const end = pageSize * currentPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(prevPage);
        }
    }
    
    const onClickForwardPage = cP => {
        if( currentPage + cP < totalPages ) {
            const start = (currentPage + cP) * pageSize;
            const nextPage = currentPage + cP + 1;
            const end = pageSize * nextPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(currentPage + 1);
        }
    }

    const renderNotFound = () => {
        const title = appContext.t('candidate.resume.review.notFoundTitle');
        const subtitles = [`${appContext.talent.firstName} ${appContext.t('candidate.resume.review.notFoundDetail')}`];
        const cssParagraph = styles.pharagrap_simple;
        const type = InfoMessageType.feedbackNotFound;
        return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } />
    }

    const buildString = (listOfString, strDefault) => {
        if( Array.isArray(listOfString) ) {
            let lString = '';
            listOfString.map((s, index) => {
                lString = `${lString}${ index > 0 ? ', ' : ''}${s.name}`;
            })
            return lString === '' ? strDefault : lString;
        }
        return listOfString === '' ? strDefault : listOfString;
    }

    const renderReview = review => (
        <div className={ styles.review }>
            <div className={ styles.comments }>
                <div><span className={ styles.p_title }>{appContext.t('candidate.resume.review.reviewer')}:</span> {review.authorName}</div>
            </div>
            { <FeedbackStarts rate={review.rate} labelClassName={ styles.p_title } /> }
            <div className={ styles.comments }>
                <div><span className={ styles.p_title }>{appContext.t('candidate.resume.review.strength')}:</span> { buildString(review.strengths, appContext.t('candidate.resume.review.notStrength')) }</div>
                <div><span className={ styles.p_title }>{appContext.t('candidate.resume.review.opportunities')}:</span> { buildString(review.opportunities, appContext.t('candidate.resume.review.notOpportunities')) }</div>
            </div>
        </div>
    );

    const renderEvaluations = () => reviewsPagged.map((review, index) => (
        <div key={ index } className={ styles.review_card } onClick={ () => onClickDetail(review) }>
            <div className={ styles.section }>
                <div className={ styles.title }>
                    <div className={ styles.title_bar } />
                    <div className={ styles.title_rewiew }>
                        <h2 className={ styles.p_title }>{appContext.t('candidate.resume.review.title')} {review.period}</h2>
                    </div>
                    <div className={ `${styles.title_bar} ${styles.title_bar_thin_gray}` } />
                    <div className={ styles.stars }>
                        <span className={ styles.p_title }>{appContext.t('candidate.list.headers.status')}: </span>
                        { review.processStatus?.name || getStatusDescription(review.processStatus.id, catFeedbackStatus) }
                        <div className={ styles.stars }>{ <FeedbackArrows status={review.processStatus?.id} catStatus={catFeedbackStatus} /> }</div>
                    </div>
                </div>
                <div className={ styles.content }>
                    { renderReview(review) }
                </div>
            </div>
            <img className={ styles.arrow_view } src={ arrowView } />
        </div>
    ))

    const renderMain = () => (
        <div className={ styles.feedback }>
            { reviewsPagged && reviewsPagged.length > 0 ? renderEvaluations() : (reviewsPagged && reviewsPagged.length === 0 ? renderNotFound() : undefined) }
            { reviewsPagged && reviewsPagged.length > 0 &&
                <Pagination
                    totalElements={ reviews.length }
                    currentPage={ currentPage }
                    totalPages={ totalPages }
                    onClickBackPage={ onClickBackPage }
                    onClickForwardPage={ onClickForwardPage }
                />
            }
        </div>
    )

    return reviewSelected && Object.keys(appContext.talent).length > 1 ?
        <FeedbackDetail
            review={ reviewSelected }
            setReview={setReviewSelected}
            reviews={evaluations}
            setReviews={setEvaluations}
            catType={ catType }
            catFeedbackStatus={catFeedbackStatus}
            isFirstReport={ isFirstReport }
            setIsFirstReport={ setIsFirstReport }
        />
        :
        renderMain();
}
